import apiRequest from "../../middlewares/api-request";
import axios from "axios";
const signup = {
  state: {
    csrf: "",
    token: "",
    user_id: "",
    cards: [],
  },
  mutations: {
    SET_CSRF_TOKEN: (state, res) => {
      state.csrf = res;
    },
    SET_CLIENT_ID: (state, res) => {
      state.user_id = res.data.data.client_id;
    },
    SET_CARD: (state, { data }) => {
      state.cards.push(data.card);
      state.cards = [...new Set(state.cards.map(JSON.stringify))].map(
        JSON.parse
      );
    },
  },
  actions: {
    async CREATE_USER({ commit }, payload) {
      return new Promise((resolve) => {
        let res = axios.post(
          "https://crm.acado.market/api/clients/store",
          payload
        );
        if (res?.status == 422) {
          this.dispatch("addNotification", { message: res?.data?.message });
        }
        if (res?.data?.code == 200) {
          commit("SET_CLIENT_ID", res);
        }
        resolve(res);
      });
    },
    async SEND_CONTACTS({ commit, state }, { fields }) {
      return new Promise((resolve) => {
        let res = axios.post(
          "https://crm.acado.market/api/clients/contacts/store",
          fields,
          {
            headers: {
              Authorization: "Bearer " + this.getters.TOKEN,
            },
          }
        );
        resolve(res);
      });
      return await apiRequest.function(
        "POST",
        `api/clients/contacts/store`,
        null,
        null,
        fields,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
    },
    async SEND_PASS({ commit }, { fields }) {
      return new Promise((resolve) => {
        let res = axios.patch(
          "https://crm.acado.market/api/clients/passport_data",
          fields,
          {
            headers: {
              Authorization: "Bearer " + this.getters.TOKEN,
            },
          }
        );
        resolve(res);
      });
      return await apiRequest.function(
        "PATCH",
        `api/clients/passport_data`,
        null,
        null,
        fields,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
    },
    async SEND_ADDRESS({ commit }, { fields }) {
      return new Promise((resolve, reject) => {
        axios
          .post("https://crm.acado.market/api/clients/address", fields, {
            headers: {
              Authorization: "Bearer " + this.getters.TOKEN,
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            resolve(error.response);
          });
      });
      return await apiRequest.function(
        "POST",
        `api/clients/address`,
        null,
        null,
        fields,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
    },
    async UPLOAD_PHOTO({ commit }, { fields }) {
      let res = await apiRequest.function(
        "POST",
        `api/files/add`,
        null,
        null,
        fields,
        {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.getters.TOKEN}`,
        }
      );
      return res;
    },
    async UPLOAD_PHOTO_AUTH({ commit }, { fields }) {
      let res = await apiRequest.function(
        "POST",
        `api/files/append/auth`,
        null,
        null,
        fields,
        {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.getters.TOKEN}`,
        }
      );
      return res;
    },
    async DELETE_PHOTO({ commit }, id) {
      if (id !== '') {
        let res = await apiRequest.function(
          "DELETE",
          `api/files/delete/${id}/auth`,
          null,
          null,
          null,
          {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.getters.TOKEN}`,
          }
        );
        return res;
      }
      return true;
    },
    async REPLACE_PHOTO({ commit }, { id, fields }) {
      let res = await apiRequest.function(
        "POST",
        `api/files/update/${id}/auth`,
        null,
        null,
        fields,
        {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.getters.TOKEN}`,
        }
      );
      return res;
    },
    async SEND_JOB_DATA({ commit }, { fields }) {
      return await apiRequest.function(
        "POST",
        `api/clients/createJobData`,
        null,
        null,
        fields,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
    },
    async GET_BIND_CARD_LINK({ commit }, client) {
      return await apiRequest.function(
        "GET",
        `api/pay/link`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
    },
    async SET_CLIENT_SERVICES({ commit }, { insurance }) {
      return await apiRequest.function(
        "POST",
        `api/clients/services`,
        null,
        null,
        { dok: false, rejection_reason: false, insurance },
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
    },
    async GET_BIND_CARD_CALLBACK({ commit }, { search, client }) {
      let res = await apiRequest.function(
        "POST",
        `api/pay/callback/bes2pay${search}`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
      if (res == undefined) return;
      const { data, code, message } = res.data;
      if (code === 200) {
        commit("SET_CARD", res.data);
        commit("SET_TOKEN", res.data.data.token);
      }
      return res;
    },
    async SEND_BID({ commit }, { fields, url = "" }) {
      return new Promise((resolve) => {
        axios
          .post(`https://crm.acado.market/api/orders/store${url}`, fields, {
            headers: {
              Authorization: "Bearer " + this.getters.TOKEN,
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            resolve(error);
          });
      });
      return await apiRequest.function(
        "POST",
        `api/orders/store`,
        null,
        null,
        fields,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
    },
    async GET_STEP({ commit }, client) {
      let res = await apiRequest.function(
        "GET",
        `api/clients/${client}/getStep`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
      return res;
    },
    async BID_VERIFY({ commit }, payload) {
      const res = await apiRequest.function(
        "POST",
        `api/orders/verify`,
        null,
        null,
        payload,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
      return res;
    },
  },
  getters: {
    CARDS(state) {
      return state.cards;
    },
  },
};

export default signup;
