import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import components from "@/components/UI";
import Maska from "maska";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import axios from "axios";

const app = createApp(App);

components.forEach((component) => {
  app.component(component.name, component);
});
// const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
//   cluster: process.env.VUE_APP_PUSHER_CLUSTER,
// });
window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  encrypted: true,
  authEndpoint: "https://crm.acado.market/api/broadcasting/auth",
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        axios
          .post(
            "https://crm.acado.market/api/broadcasting/auth",
            {
              socket_id: socketId,
              channel_name: channel.name,
            },
            { headers: { Authorization: `Bearer ${store.getters.TOKEN}` } }
          )
          .then((response) => {
            callback(false, response.data);
          })
          .catch((error) => {
            callback(true, error);
          });
      },
    };
  },
});

app.use(store).use(router).use(Maska).mount("#app");
